exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-competitions-index-js": () => import("./../../../src/pages/competitions/index.js" /* webpackChunkName: "component---src-pages-competitions-index-js" */),
  "component---src-pages-competitions-slug-js": () => import("./../../../src/pages/competitions/[...slug].js" /* webpackChunkName: "component---src-pages-competitions-slug-js" */),
  "component---src-pages-cookies-policy-index-js": () => import("./../../../src/pages/cookies-policy/index.js" /* webpackChunkName: "component---src-pages-cookies-policy-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-recipes-slug-js": () => import("./../../../src/pages/recipes/[...slug].js" /* webpackChunkName: "component---src-pages-recipes-slug-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */)
}

